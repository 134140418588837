* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
    display: none;
}

.header{
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.logo{
    font-size: 25px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    cursor: default;
    opacity: 0;
    animation: slideRight 1s ease forwards;
}

.logo span{
    color: #3975EA;
}

.navbar a{
    display: inline-block;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    margin-left: 35px;
    transition: .3s;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    transition: .3s ease-in-out;
}

@media (max-width: 1280px) {
    .navbar{
        display: none;
    }

}

#nav1{
    animation-delay:  .2s;
}

#nav2{
    animation-delay:  .4s;
}
#nav3{
    animation-delay:  .6s;
}

#nav4{
    animation-delay:  .8s;
}
#nav5{
    animation-delay:  1s;
}


.navbar a:hover,
.navbar a.active{
    color:#3975EA ;
}

.navbar a .liner{
    width: 100%;
    background-color: #3975EA;
    height: 1px;
    opacity: 0;
}

.navbar a:hover .liner{
    animation: updateRight .5s ease  forwards;
}



@keyframes slideRight {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideTop {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes updateRight {
    0% {
        transform:scaleX(0);
        opacity: 0;
    }

    100% {
        transform: scaleX(1);
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes floatImage {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-24px);
    }
    100% {
        transform: translateY(0);
    }
}