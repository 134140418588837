.about{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 8% 2rem;
    min-height: 100vh;
}

.experience{
    padding: 6% 6% 12% 6%;
}

.about-img {
    flex: 0 0 auto;
    width: 300px;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    opacity: 0;
    animation: slideRight 1s ease forwards;
    display: block;
}

.about-content {
    flex: 1;
    max-width: 600px;
}

@media (max-width: 986px) {
    .about {
        flex-direction: column;
        text-align: center;
        gap: 2rem;
        padding: 6rem 1rem 3rem;
        min-height: auto;
    }

    .about-img {
        width: 180px;
        height: 180px;
        margin: 0 auto;
        flex-shrink: 0;
    }

    .about-content {
        padding: 0 1rem;
        width: 100%;
    }

    .about-content h2 {
        text-align: center;
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 480px) {
    .about {
        padding-top: 4rem;
        gap: 1rem;
        min-height: auto;
        padding-bottom: 2rem;
        overflow: visible;
    }

    .experience {
        padding: 2rem 1rem;
        overflow: visible;
    }

    .about-content {
        padding: 0 1rem;
        overflow: visible;
    }

    .about-content h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .about-content h3 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .about-content p {
        font-size: 0.95rem;
        line-height: 1.5;
        margin-bottom: 1rem;
        overflow: visible;
    }

    .experience-container {
        padding: 0 1rem;
        gap: 1rem;
        overflow: visible;
    }

    .experience-container .experience-box {
        min-height: auto;
        padding: 1.5rem 1rem;
        margin-bottom: 1rem;
    }

    .experience-box p {
        -webkit-line-clamp: initial;
        overflow: visible;
        text-overflow: initial;
        display: block;
    }

    .experience h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        padding-top: 1rem;
    }

    .heading {
        font-size: 2rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
    }

    .home-img {
        display: none;
    }

    .about-img {
        width: 140px;
        height: 140px;
    }

    .about-btn {
        padding: 0.8rem 2rem;
        font-size: 0.9rem;
        margin: 1.5rem 0;
    }
}

@media (min-width: 481px) and (max-width: 986px) {
    .about {
        padding: 5rem 2rem 3rem;
        min-height: auto;
        overflow: visible;
    }

    .experience {
        padding: 3rem 2rem;
        overflow: visible;
    }

    .experience-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .experience-box p {
        -webkit-line-clamp: initial;
        overflow: visible;
    }
}

.heading, .heading-text {
    text-align: center;
    font-size: 4.5rem;
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    width: 100%;
    display: block;
}

.heading span, .heading-text span {
    color: #3975EA;
}

.about-content h3{
    font-size: 2.2rem;
    margin-bottom: 2.2rem;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    cursor: default;
}

.about-content p {
    font-size: 16px;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    cursor: default;
}

.about-btn{
    display: inline-block;
    padding: 1rem 2.8rem;
    background: #3975EA;
    box-shadow: 0 0 10px #3975EA;
    border-radius: 4rem;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    letter-spacing: .1rem;
    font-weight: 600;
    margin: 3rem 0 2rem;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    cursor: pointer;
    border: none;
}

.about-btn:hover{
    box-shadow: none;
}

.back-btn{
    position: fixed;
    top: 5.5rem;
    right: 4rem;
    width: 5rem;
    line-height: 2;
    background: #3975EA;
    box-shadow: 0 0 10px #3975EA;
    border-radius: 4rem;
    font-size: 16px;
    font-weight: 500;
    color: #ffff;
    text-decoration: none;
    letter-spacing: .1rem;
    font-weight: 600;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    cursor: pointer;
    border: none;
}

.back-btn:hover:hover{
    box-shadow: none;
}

.experience h2{
    font-size: 3rem;
    margin-bottom: 3rem;
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    cursor: default;
}

.experience-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    opacity: 0;
    animation: slideTop 1s ease forwards;
}

.experience-container.deactive{
    display: none;
}

.experience-container .experience-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #323842;
    padding: 3rem 2rem;
    border-radius: 2rem;
    text-align: center;
    border: .2rem solid #20242C;
    transition: .5s ease;
    height: 100%;
    min-height: 320px;
}

.experience-box .icon-container {
    margin-bottom: 1.5rem;
}

.experience-box h3{
    font-size: 1.4rem;
    margin-bottom: 1rem;
    cursor: default;
}

.experience-box p {
    font-size: 1rem;
    margin-bottom: 2rem;
    cursor: default;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.experience-container .experience-box:hover{
    border-color: #3975EA;
    transform: scale(1.02);
}

@media (max-width: 960px) {
    .experience-container {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        padding: 0 1rem;
    }
}

@media (max-width: 480px) {
    .experience-container {
        grid-template-columns: 1fr;
    }
    
    .experience-container .experience-box {
        min-height: 280px;
    }
}

.experience-container .new-box{
    background: #323842;
    padding: 3rem 1rem 2rem;
    border-radius: 2rem;
    text-align: center;
    transition: .5s ease;
    opacity: 0;
    animation: slideTop 1s ease forwards;
}

.experience-container .new-box h1{
    font-size: 24px;
    font-weight: 600;
    color: #AADAFA;
}
.experience-container .new-box h2{
    font-size: 24px;
    font-weight: 600;
    color: #71C4AE;
}
.experience-container .new-box h3{
    font-size: 24px;
    font-weight: 600;
    color: #BC89BD;
}

#new-content p{
    text-align: left;
    margin: 2.8rem;
}

.new-box p {
    font-size: 1rem;
    margin: 1rem 0;
    cursor: default;
}

