.home{
    position: relative;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12% 6% 12% 15%;
}

@media (max-width: 986px) {
   
    .home{
        display: grid;
        grid-template-rows: 1fr 1fr;
        width: 90%;
        min-width: 550px;
    }

    .home-img img{
        display: none;
    }
}

@media (max-width: 1280px) {
    .home-img img{
        width: 300px;
    }

}

.home-content{
    min-width: 500px;

}



.home-content h3{
    font-size: 32px;
    font-weight: 700;
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    animation-delay: .7s;
    cursor: default;
    min-width: 500px;
}

.home-content h3:nth-of-type(2){
    margin-bottom: 30px;
    opacity: 0;
    animation: slideTop 1s ease forwards;
    animation-delay: .7s;
}

.home-content h3 span{
    color: #3975EA;
}

.home-content h1{
    font-size: 56px;
    font-weight: 700;
    margin: -3px 0;
    opacity: 0;
    animation: slideRight 1s ease forwards;
    animation-delay: 1s;
    cursor: default;
}

.home-content p {
    font-size: 16px;
    opacity: 0;
    animation: slideLeft 1s ease forwards;   
    cursor: default;
}

.home-content p:nth-of-type(1) {
    animation-delay: 1s;
}

.home-content p:nth-of-type(2) {
    animation-delay: 1s;
}

.home-content p:nth-of-type(3) {
    animation-delay: 2s;
}

.home-content p:nth-of-type(4) {
    animation-delay: 2s;
}

.social-media a{
    display: inline-flex;
    
    border-radius: 50%;
    height: 40px;
    background: transparent;
    justify-content: center;
    align-items: center;
    width: 40px;
    font-size: 20px;
    text-decoration: none;
    margin: 30px 15px 30px 0;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: 1s;
    border: 2px solid #3975EA;
    color: #3975EA;
}

#icon1{
    animation-delay:  .2s;
    
}

#icon2{
    animation-delay:  .4s;
}


#icon3{
    animation-delay:  .6s;
}

#icon4{
    animation-delay:  .8s;
}

.social-media a:hover{
    background: #3975EA;
    color: #1f242d;
    box-shadow: 0 0 20px #3975EA;
}

.download-btn{
    display: inline-block;
    padding: 1rem 2.8rem;
    background: #DF6852;
    box-shadow: 0 0 10px #DF6852;
    border-radius: 4rem;
    font-size: 16px;
    color: #000;
    text-decoration: none;
    letter-spacing: .1rem;
    font-weight: 600; 
    opacity: 0;
    animation: slideTop 1s ease forwards;
    animation-delay: 2s;
    transition: .5s ease;
}

.download-btn:hover{
    box-shadow: none;
}


.home-img img{
    max-width: 450px;
    margin-right: -20px;
    opacity: 0;
    border-radius: 4rem;
    box-shadow: 0 0 20px#DF6852;
    animation: zoomIn 1s ease forwards, floatImage 4s ease-in-out infinite;
    animation-delay: 1.5s, 3s;
    
}

@media (max-width: 480px) {
    .home {
        padding: 5rem 1.5rem 2rem;
        min-width: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .home-content {
        min-width: auto;
        width: 100%;
        padding: 0;
    }

    .home-content h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        word-wrap: break-word;
    }

    .home-content h3 {
        font-size: 1.5rem;
        min-width: auto;
        margin-bottom: 1rem;
    }

    .home-content p {
        font-size: 0.95rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    .social-media {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .social-media a {
        margin: 0.5rem;
    }

    .download-btn {
        padding: 0.8rem 2rem;
        font-size: 0.9rem;
        margin: 1.5rem auto;
        display: block;
        text-align: center;
    }
}

@media (min-width: 481px) and (max-width: 986px) {
    .home {
        padding: 6rem 2rem 3rem;
        width: 100%;
        min-width: auto;
    }

    .home-content {
        min-width: auto;
        width: 100%;
    }

    .home-content h1 {
        font-size: 3rem;
    }

    .home-content h3 {
        font-size: 1.8rem;
        min-width: auto;
    }
}